import {isDefined, valueOf, valueOfOr} from "puppit-shared/fn";
import {fromEvent, textRegex, validate} from "../fn/fn";
import React, {useState} from "react";
import {
    FORM,
    INTERNAL_ID,
    ISSUE_CREATOR,
    ISSUE_LINK,
    ISSUE_QUESTION,
    ISSUE_STATE,
    ISSUE_SUMMARY,
    REPLY,
    REVIEW,
    STATE
} from "../fn/dict";
import {checkPartnerVote, mapToData, updatePartnerVote} from "../fn/api";
import {MANAGE_VOTES_PAGE} from "../fn/navigation-fn";
import {Navigate, useLocation} from "react-router-dom";
import {renderFeedback} from "../fn/partner-fn";


const CreateVoteFlow = () => {

    const location = useLocation()
    const state = valueOf( STATE )( location )

    const internalId = valueOfOr( INTERNAL_ID, undefined )( state );
    const issueCreator = valueOfOr( ISSUE_CREATOR, undefined )( state );
    const issueState = valueOfOr( ISSUE_STATE, undefined )( state );
    const initialSummary = valueOfOr( ISSUE_SUMMARY, undefined )( state );
    const initialLink = valueOfOr( ISSUE_LINK, undefined )( state );
    const initialQuestion = valueOfOr( ISSUE_QUESTION, undefined )( state );

    const [isProcessing, setIsProcessing] = useState( false );
    const [summary, setSummary] = useState( initialSummary );
    const [link, setLink] = useState( initialLink );
    const [question, setQuestion] = useState( initialQuestion );
    const [voiceOfMachineGod, setVoiceOfMachineGod] = useState( "" );
    const [currentScreen, setCurrentScreen] = useState( FORM )
    const [redirect, setRedirect] = useState( undefined );

    const summaryFieldComplete = () => isDefined( summary ) ? summary.trim() !== "" : false;
    const questionFieldComplete = () => isDefined( question ) ? question.trim() !== "" : false;
    const formIsComplete = () => summaryFieldComplete() && questionFieldComplete()
    const summaryFieldChanged = () => summary !== initialSummary;
    const linkFieldChanged = () => link !== initialLink;
    const questionFieldChanged = () => question !== initialQuestion;
    const formChanged = () => summaryFieldChanged() || linkFieldChanged() || questionFieldChanged()

    const reviewForm = () => {
        const data = {
            issueTitle: 'unused',
            issueSummary: summary,
            issueQuestion: question
        }

        setIsProcessing( true );
        checkPartnerVote( data ).then( mapToData )
                                .then( valueOf( REPLY ) )
                                .then( renderFeedback )
                                .then( setVoiceOfMachineGod )
                                .finally( () => {
                                    setIsProcessing( false );
                                    setCurrentScreen( REVIEW );
                                } )
    }

    const submitForm = () => {

        const data = {
            internalId: internalId,
            issueCreator: issueCreator,
            issueState: issueState,
            issueTitle: 'unused',
            issueQuestion: question,
            issueSummary: summary,
            issueLink: link
        }

        setIsProcessing( true );
        updatePartnerVote( data ).finally( () => {
            setIsProcessing( false );
            setRedirect( MANAGE_VOTES_PAGE )
        } )

    }

    if ( isDefined( redirect ) )
        return <Navigate to={redirect}/>

    return (
        <>
            <h1 className="text-center mb-3">Manage Votes</h1>

            <form hidden={currentScreen !== FORM}>

                <b style={{ fontSize: '1.7rem' }}>Question</b>
                <div className="mb-3">
                    <label htmlFor="question" className="form-label">Please enter the question to be asked.</label>
                    <input type="text"
                           disabled={isProcessing}
                           onKeyDown={validate( textRegex )}
                           className="form-control text-boxes"
                           onChange={fromEvent( setQuestion )}
                           id="question"
                           defaultValue={initialQuestion}/>
                </div>

                <b style={{ fontSize: '1.7rem' }}>Summary</b>
                <div className="mb-3">
                    <label htmlFor="summary" className="form-label">Please enter a summary of the issue to be
                        decided.</label>
                    <textarea disabled={isProcessing}
                              onKeyDown={validate( textRegex )}
                              rows="6"
                              className="form-control text-boxes"
                              onChange={fromEvent( setSummary )}
                              id="summary"
                              defaultValue={initialSummary}/>
                </div>

                <b style={{ fontSize: '1.7rem' }}>Link</b>
                <div className="mb-3">
                    <label htmlFor="link" className="form-label">An optional link to external information.</label>
                    <input disabled={isProcessing}
                           type="text"
                           placeholder="https://www.blocvote.org"
                           className="form-control text-boxes"
                           onChange={fromEvent( setLink )}
                           id="ink"
                           defaultValue={initialLink}
                    />
                </div>

                <div className='d-flex justify-content-between mt-4'>

                    <button onClick={() => setRedirect( MANAGE_VOTES_PAGE )}
                            disabled={isProcessing}
                            type="button"
                            className="btn btn-outline-dark  rounded">
                        Back
                    </button>

                    <button onClick={reviewForm}
                            disabled={!formIsComplete() || !formChanged() || isProcessing}
                            type="button"
                            className="btn btn-outline-success  rounded">
                        Continue
                    </button>


                </div>
            </form>

            <div hidden={currentScreen !== REVIEW}>
                <h3 className="mb-3">Preview</h3>

                <b style={{ fontSize: "1.2rem" }}>Question: </b>
                <div className="mb-3"> {question} </div>

                <b style={{ fontSize: "1.2rem" }}>Summary: </b>
                <div className="mb-3"> {summary} </div>

                <h3 className="mt-5 mb-3">Feedback</h3>
                <div className="mb-3">{voiceOfMachineGod}</div>

                <div className='d-flex justify-content-between mt-4'>
                    <button onClick={() => setCurrentScreen( FORM )}
                            disabled={isProcessing}
                            className="btn btn-outline-dark  rounded ">
                        Back
                    </button>
                    <button onClick={submitForm}
                            disabled={isProcessing}
                            className="btn btn-outline-success  rounded ">
                        Continue
                    </button>

                </div>

            </div>
        </>
    )
}

export default CreateVoteFlow
