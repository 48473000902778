import {VOTES_PAGE} from "../fn/dict";
import {Link} from "react-router-dom";

const NotFound = () => {

    return (
        <>
            <h1 className="text-center mt-3 mb-3">The page you are looking has not been found.</h1>

            <div className="card border-0">
                <div className="bi bi-box display-1 logo text-center"/>
                <h2 className="text-center" >BLOCVOTE</h2>
                <p className="fade-text text-muted mx-3 text-center">DELIVERING DIRECT DEMOCRACY</p>
                <div className="card-body">
                    <div className="d-flex justify-content-center">
                        <Link to={VOTES_PAGE} className="btn btn-block btn-outline-success rounded">
                             Home
                        </Link>
                    </div>
                </div>

            </div>
        </> )

};

export default NotFound;
