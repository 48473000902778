import React, {useEffect, useState} from "react";
import {deleteDraftVote, fetchPartnerVotes, mapToData} from "../fn/api";
import {is, isDefined} from "puppit-shared/fn";
import {DRAFT, INTERNAL_ID, ISSUE_PARTNER, ISSUE_STATE, PARTNER_ID, PUBLISHED, VOTE_CLOSE_TIMESTAMP,} from "../fn/dict";
import PartnerDraftVoteItem from "./PartnerDraftVoteItem";

import {Navigate, useNavigate} from "react-router-dom";
import {CREATE_VOTE_FLOW_PAGE, PUBLISH_VOTE_FLOW_PAGE} from "../fn/navigation-fn";
import {processingAtom, voteAccountValidAtom} from "../fn/atomic";
import {useAtom} from "jotai";
import SecurityProblemModal from "./SecurityProblemModal";
import {ascending, isProcessing, mergeLeft, minusOne, plusOne, toKeyedDiv} from "../fn/fn";
import VoteCard from "./vote/VoteCard";

const ManageVotes = () => {

    const [processing, setProcessing] = useAtom(processingAtom);
    const [issues, setIssues] = useState([]);
    const [redirect, setRedirect] = useState(undefined);
    const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
    const [voteAccountValid,] = useAtom(voteAccountValidAtom);

    const closeSecurityModal = () => setSecurityModalIsOpen(false)
    const openSecurityModal = () => setSecurityModalIsOpen(true)


    useEffect(() => {

        setProcessing(plusOne)
        const abort = new AbortController();
        fetchPartnerVotes(abort)
            .then(mapToData)
            .then(setIssues)
            .finally(() => setProcessing(minusOne))

        return () => abort.abort();

        // eslint-disable-next-line
    }, []);


    const onAfterVote = vote => {
        setIssues(izzues => izzues.map(izzue => (izzue[INTERNAL_ID] === vote[INTERNAL_ID]) ? mergeLeft(izzue, vote) : izzue));
    }

    const handleDelete = vo => {

        setProcessing(plusOne);
        deleteDraftVote(vo)
            .then(mapToData)
            .then(setIssues)
            .finally(() => setProcessing(minusOne))
    }


    const navigate = useNavigate();

    const showFailedSecurityCheckWarning = () => voteAccountValid.isFalse()

    const onPublish = vo => () => {

        if (showFailedSecurityCheckWarning())
            openSecurityModal()
        else
            navigate(PUBLISH_VOTE_FLOW_PAGE, {state: vo})
    }

    const factory = canEdit => (vo, idx) => {
        return <PartnerDraftVoteItem key={'li-' + idx}
                                     showQuestion={false}
                                     processing={processing}
                                     onEdit={() => navigate(CREATE_VOTE_FLOW_PAGE, {state: vo})}
                                     onPublish={onPublish(vo)}
                                     onDelete={() => handleDelete(vo)}
                                     canEdit={canEdit}
                                     data={vo}/>
    }


    const draft = issues.filter(is(ISSUE_STATE, DRAFT))

    const published = issues.filter(is(ISSUE_STATE, PUBLISHED))
    const hasDraft = draft.length > 0;

    issues[PARTNER_ID] = issues[ISSUE_PARTNER];


    if (isDefined(redirect))
        return <Navigate to={redirect}/>

    return (
        <>
            <SecurityProblemModal isOpen={securityModalIsOpen} close={closeSecurityModal}/>

            <h1 className="text-center">Manage Votes</h1>

            <ul className="list-group list-group-flush px-0 ">

                <li className="list-group-item px-0 border-0 ">

                    <div className="d-flex justify-content-between">
                        <h3 className="mb-2">Draft Votes</h3>

                        <button onClick={() => setRedirect(CREATE_VOTE_FLOW_PAGE)}
                                className="btn btn-outline-dark rounded "
                                disabled={isProcessing(processing)}>
                            Create Vote
                        </button>

                    </div>
                </li>

                <li className={'list-group-item px-0 border-0 ' + (hasDraft ? ' d-none ' : '')}>
                    <div className="h5 pt-4 text-muted text-center">No draft votes.</div>
                </li>

                {draft.map(factory(true))}
            </ul>

            <h3>Published Votes</h3>

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                {published.sort(ascending(VOTE_CLOSE_TIMESTAMP))
                    .map(vo => <VoteCard data={vo} onAfterVote={onAfterVote}/>)
                    .map(toKeyedDiv("col mb-3", "voted"))}
            </div>


        </>)
}

export default ManageVotes;
