const Error = () => {

    return (
        <div className="container mt-3">

            <h1 className="text-center mb-3">Oh no, this is embarrassing.</h1>

            <h3 className="text-center mb-4 text-muted"> Please try again later, we're going to get started fixing
                things. </h3>

        </div> );
};

export default Error;
