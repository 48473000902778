import React, {useState} from 'react';
import {clog, coalesce, isDefined, isNotDefined, valueOf} from "puppit-shared/fn";
import {Uploady, useItemErrorListener, useItemFinishListener, useItemStartListener} from "@rpldy/uploady";
import {has, isNotProcessing, isProcessing, minusOne, plusOne} from "../../fn/fn";
import {apiUrlFor, cancelUpload, mapToData, submitKycVerification} from "../../fn/api";
import {useAtom} from "jotai";
import {deviceContextAtom, processingAtom, sessionTokenAtom, userAtom} from "../../fn/atomic";
import {asUploadButton} from "@rpldy/upload-button";
import UploadPreview from "@rpldy/upload-preview";
import {DOCUMENT_ID, OLD_VOTE_ACCOUNT_CYPHER, VOTE_ACCOUNT_CYPHER} from "../../fn/dict";
import {EMAIL} from "puppit-shared/dict";
import {extractVoteAccountCypher} from "../../fn/user";

const DocumentUpload = ( { onPrev, onNext } ) => {

    const [user,] = useAtom( userAtom );
    const [sessionToken,] = useAtom( sessionTokenAtom );
    const [processing, setProcessing] = useAtom( processingAtom );
    const [deviceContext, setDeviceContext] = useAtom( deviceContextAtom );

    const [action, setAction] = useState( undefined )
    const [documentId, setDocumentId] = useState( undefined );

    const storeVoteAccountCypherInDeviceContextIfNecessary = vo => {

        const newCypher = valueOf( VOTE_ACCOUNT_CYPHER )( vo );
        const userEmail = valueOf( EMAIL )( user );

        if ( isNotDefined( newCypher ) || isNotDefined( userEmail ) )
            return vo;

        const usersDeviceContext = coalesce( deviceContext[ userEmail ], {} );

        if ( has( VOTE_ACCOUNT_CYPHER )( usersDeviceContext ) )
            usersDeviceContext[ OLD_VOTE_ACCOUNT_CYPHER ] = usersDeviceContext[ VOTE_ACCOUNT_CYPHER ];

        usersDeviceContext[ VOTE_ACCOUNT_CYPHER ] = newCypher;

        setDeviceContext( dc => {
            dc[ userEmail ] = usersDeviceContext;
            return dc;
        } );

        return vo;
    }

    const SUBMIT = 'submit';
    const CANCEL = 'cancel';

    const canSubmit = () => isDefined( documentId );
    const isCancelling = action => action === CANCEL;
    const isSubmitting = action => action === SUBMIT;


    const handleCancel = () => {

        setProcessing( plusOne );
        setAction( CANCEL );

        cancelUpload( documentId ).finally( () => {
            setDocumentId( undefined );
            setProcessing( minusOne );
            setAction( undefined )
        } );
    }

    const handleSubmit = () => {


        const vac = extractVoteAccountCypher( user, deviceContext );
        clog( '1' );
        const vo = {
            [DOCUMENT_ID]: documentId,
            [VOTE_ACCOUNT_CYPHER]: vac
        }

        setProcessing( plusOne );
        setAction( SUBMIT );
        clog( '2' );
        submitKycVerification( vo ).then( mapToData )
                                   .then( storeVoteAccountCypherInDeviceContextIfNecessary )
                                   .then( onNext )
                                   .catch( clog )
                                   .finally( () => {
                                       setProcessing( minusOne )
                                       setAction( undefined )
                                   } );
    }

    const previewComponent = props => {

        if ( props.isFallback || isNotDefined( documentId ) )
            return <div className='max-h-25 h-25 bg-light'></div>

        return <img className="img img-fluid max-h-25 h-25 mb-2" src={props.url} alt={props.name}/>
    }

    const destination = {
        url: apiUrlFor( "/upload" ),
        headers: {
            'X-Session-Token': sessionToken,
            'uploadPurpose': 'kyc'
        }
    };

    const UploadyCallbacks = () => {

        useItemStartListener( ( item ) => {
            setDocumentId( undefined );
            setProcessing( plusOne );
        } );

        useItemFinishListener( ( item ) => {
            setDocumentId( item?.uploadResponse?.data.documentId );
            setProcessing( minusOne );
        } );

        useItemErrorListener( ( item ) => {
            setDocumentId( undefined );
            setProcessing( minusOne );
        } );


        return <></>
    }

    const UploadButton = asUploadButton( ( props ) => {
        return <button
            disabled={isProcessing( processing )} {...props}
            className="btn btn-outline-success btn-block rounded mt-2">
            <span hidden={isNotProcessing( processing )}><span
                className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>Select
        </button>
    } );


    return (
        <div className="card border-0  mh-60">
            <div className="card-body">
                <h5 className="card-title h2">Verification</h5>
                <p hidden={canSubmit()}
                   className="card-text text-muted">
                    Please upload a document which clearly shows your name and zipcode.
                    We accept driving licences, official documents and bank statements, etc.</p>

                <Uploady destination={destination} accept="image/*,.pdf">
                    <UploadyCallbacks/>

                    <div className="mt-4">
                        <UploadPreview PreviewComponent={previewComponent}/>
                    </div>

                    <div className="d-flex mt-4 justify-content-between">

                        <button hidden={canSubmit()} disabled={isProcessing( processing )}
                                className="btn btn-outline-dark mt-2"
                                onClick={onPrev}>Back
                        </button>

                        <div hidden={canSubmit()}><UploadButton/></div>


                        <button disabled={isProcessing( processing )}
                                hidden={!canSubmit()}
                                className="btn btn-outline-dark rounded mt-2"
                                onClick={handleCancel}>
                            <span hidden={isNotProcessing( processing ) || isSubmitting( action )}><span
                                className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>Cancel
                        </button>

                        <button onClick={handleSubmit}
                                hidden={!canSubmit()}
                                disabled={isProcessing( processing )}
                                className="btn btn-primary rounded mt-2">
                            <span hidden={isNotProcessing( processing ) || isCancelling( action )}><span
                                className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>Upload
                        </button>
                    </div>
                </Uploady>
            </div>
        </div>
    );
}

export default DocumentUpload;
